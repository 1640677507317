import {
  HTTP_STATUS_CODE_NOT_FOUND,
  useFileUploadSession,
  useHasSession,
  useSessionState,
  useSessionStatePrefix,
} from "raci-react-library";
import { useEffect, useState } from "react";
import { NavigateOptions, useLocation, useNavigate } from "react-router-dom";
import { STORAGE_KEY_CLAIM_NUMBER } from "../../../../../shared/constants";
import { useBffApiClient, useDocumentServiceApiClient } from "../../../../../shared/hooks/useApiClient";
import { ClaimsServicingApiException } from "raci-claims-servicing-clientproxy";
import useClaimNumber from "../../../../../shared/hooks/useClaimNumber";
import {
  UPLOAD_INVOICE_PRE_FORM_ROUTE_KEY,
  UploadInvoiceErrorRoute,
  UploadInvoiceFormRoute,
  getUploadInvoiceUrl,
} from "../../../../../shared/routing/uploadInvoiceRoutes.config";
import useUploadInvoiceFileUpload from "../../../hooks/useUploadInvoiceFileUpload";
import { PreFormState } from "../../types";

export const usePreForm = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const apiClient = useBffApiClient();
  const hasSession = useHasSession();
  const claimNumber = useClaimNumber();
  const storageKeyPrefix = useSessionStatePrefix();
  const [preFormState, setPreFormState] = useSessionState<PreFormState>({
    specificKey: UPLOAD_INVOICE_PRE_FORM_ROUTE_KEY,
  });
  const [completedValidation, setCompletedValidation] = useState(false);
  const [isClaimValid, setIsClaimValid] = useState(false);
  const documentServiceApiClient = useDocumentServiceApiClient();
  const { getSession: getFileUploadSession } = useFileUploadSession({
    apiClient: documentServiceApiClient,
    onError: () =>
      navigateToNextPage(UploadInvoiceErrorRoute.SystemUnavailable, {
        state: { referrer: location.pathname },
      }),
  });
  const { fetchConfiguration: fetchFileUploadConfiguration } = useUploadInvoiceFileUpload();

  const navigateToNextPage = (route: UploadInvoiceFormRoute | UploadInvoiceErrorRoute, options?: NavigateOptions) =>
    navigate(getUploadInvoiceUrl(route), options);

  useEffect(() => {
    if (completedValidation) {
      navigateToNextPage(isClaimValid ? UploadInvoiceFormRoute.UploadAndSubmit : UploadInvoiceErrorRoute.LinkExpired);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [completedValidation]);

  useEffect(() => {
    const validateClaim = async (claimNumber: string) => {
      try {
        const { isClaimExpired, contactFirstName, token } = (await apiClient.validateUploadInvoice(claimNumber)).result;
        // TODO SPK-6162: Make token a required property of the response so existence check does not need to be made
        const isClaimValid = !isClaimExpired && !!token;

        setPreFormState({
          ...preFormState,
          contactFirstName,
          isClaimValid,
          isCompleted: isClaimValid,
        });

        if (isClaimValid) {
          await getFileUploadSession();
          await fetchFileUploadConfiguration(token);
        }

        setIsClaimValid(isClaimValid);
        setCompletedValidation(true);
      } catch (e) {
        const error = e as ClaimsServicingApiException;
        if (error.status === HTTP_STATUS_CODE_NOT_FOUND) {
          sessionStorage.removeItem(`${storageKeyPrefix}${STORAGE_KEY_CLAIM_NUMBER}`);
        }
        navigateToNextPage(UploadInvoiceErrorRoute.SystemUnavailable, {
          state: {
            referrer: location.pathname,
            exception: { request: `GET /validateuploadinvoice`, status: error.status },
          },
        });
      }
    };

    if (claimNumber) {
      hasSession && validateClaim(claimNumber);
    } else {
      navigateToNextPage(UploadInvoiceErrorRoute.SystemUnavailable, {
        state: { referrer: location.pathname },
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasSession]);
};
