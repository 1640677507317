import { LoadingStatus, useSessionIdStorageKey } from "raci-react-library";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { UploadInvoiceErrorRoute, getUploadInvoiceUrl } from "../../routing/uploadInvoiceRoutes.config";
import { useBffApiClient } from "../useApiClient";
import useClaimNumber from "../useClaimNumber";

export const useCreateSession = () => {
  const navigate = useNavigate();
  const client = useBffApiClient();
  const sessionIdStorageKey = useSessionIdStorageKey();
  const claimNumber = useClaimNumber();
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    let isCancelled = false;

    const createSession = async () => {
      setIsError(false);

      const hasSessionId = sessionStorage.getItem(sessionIdStorageKey);

      try {
        if (!isCancelled && !hasSessionId) {
          if (!claimNumber) {
            setIsError(true);
          } else {
            const response = await client.createSession();
            sessionStorage.setItem(sessionIdStorageKey, response.result);
            if (response.status !== 200) {
              setIsError(true);
            }
          }
        }
      } catch {
        setIsError(true);
        navigate(getUploadInvoiceUrl(UploadInvoiceErrorRoute.SystemUnavailable));
      } finally {
        setIsLoading(false);
      }
    };

    createSession();

    return () => {
      isCancelled = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [client, sessionIdStorageKey, claimNumber]);

  return isLoading ? LoadingStatus.Loading : isError ? LoadingStatus.Error : LoadingStatus.Successful;
};

export default useCreateSession;
