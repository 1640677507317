import { DocumentServiceApiClient } from "raci-document-service-clientproxy";
import { useFileUploadSessionKey, useSessionIdStorageKey, useSsoApiClient } from "raci-react-library";
import { ADB2C_CLIENT_ID, BFF_API_BASE_URL, DOCUMENT_SERVICE_API_BASE_URL } from "../../constants";
import { ClaimsServicingApiClient } from "raci-claims-servicing-clientproxy";

const useRealBffApiClient = () => {
  const sessionIdStorageKey = useSessionIdStorageKey();

  return useSsoApiClient({
    Client: ClaimsServicingApiClient,
    clientId: ADB2C_CLIENT_ID,
    apiBaseUrl: BFF_API_BASE_URL,
    sessionIdStorageKey,
  });
};

const useRealDocumentServiceApiClient = () => {
  const sessionIdStorageKey = useFileUploadSessionKey();

  return useSsoApiClient({
    Client: DocumentServiceApiClient,
    clientId: ADB2C_CLIENT_ID,
    apiBaseUrl: DOCUMENT_SERVICE_API_BASE_URL,
    sessionIdStorageKey,
  });
};

export let useBffApiClient = useRealBffApiClient;
export let useDocumentServiceApiClient = useRealDocumentServiceApiClient;

if (
  process.env.REACT_APP_ENVIRONMENT === "LOCAL" ||
  process.env.REACT_APP_ENVIRONMENT === "DEV" ||
  process.env.REACT_APP_ENVIRONMENT === "SIT" ||
  process.env.REACT_APP_ENVIRONMENT === "UAT"
) {
  // DEV/SIT require Racwa-Internal-Authorization header when accessed outside internal network
  const { BypassService } = require("raci-react-library-npe");
  const { useNpeBffApiClient, useNpeDocumentServiceApiClient } = require("../../../__NPE__/useNpeApiClient");
  useBffApiClient = useNpeBffApiClient;
  useDocumentServiceApiClient = useNpeDocumentServiceApiClient;

  if (process.env.REACT_APP_ENVIRONMENT === "UAT" && !BypassService.isUsingTestLaunchPage()) {
    useBffApiClient = useRealBffApiClient;
    useDocumentServiceApiClient = useRealDocumentServiceApiClient;
  }
}
